import { useCallback } from 'react';

import { Address } from 'viem';

import { useConfirmListenerWithPolling, useMinedTx } from '@web3/hooks';
import { ConfirmListenerWithPollingArgs, HexPrefixValue } from '@web3/types';

import ISMCPayment from '../../abi/ISMCPayment.json';

export default function usePaymentDeposit(
  contractAddress: Address,
  { onSuccess, onError, onPoll }: ConfirmListenerWithPollingArgs
) {
  const { sendTransaction, isLoading } = useMinedTx({
    onSuccess,
    onError,
  });

  const { triggerListener } = useConfirmListenerWithPolling({ onPoll });

  // TODO: This was sent by them in the excel to hardcode for now
  // const HARDCODED_EXTRA_DATA =
  //   '0x4d61726b6574706c616365000000000000000000000000000000000000000000';

  function convertToHex(userId: number) {
    const template = `MKP:U:${userId}`;

    const hexData = Buffer.from(template, 'utf8').toString('hex');

    return `0x${hexData}`;
  }

  const deposit = useCallback(
    async (weiAmount: bigint, userId: number) => {
      const extraData = convertToHex(userId);
      const hash = await sendTransaction({
        abi: ISMCPayment,
        address: contractAddress,
        functionName: 'deposit',
        args: [weiAmount, extraData],
      });

      triggerListener(hash as HexPrefixValue);
    },
    [sendTransaction, contractAddress, triggerListener]
  );

  return { deposit, isLoading };
}
